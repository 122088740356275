import Vue from 'vue'
import App from './index.vue'
import router from '../../router/route'
import '../../plugins/axios'

Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App),
}).$mount('#app')